import request from '@/utils/request'

export function getyzmapi(data) {
  return request({
    url: '/message/sendsms',
    method: 'POST',
    data: data,
  })
}

export function updataapi(data) {
  return request({
    url: '/v2/login/updatePassword',
    method: 'POST',
    data: data,
  })
}
